import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { rootFourGrid, rootSixGrid } from './index.module.scss';
import ProjectPreview from '../project-preview';

const ProjectPreviewGrid = (props) => {
  const { categorySlug, projects } = props;
  return (
    <div className={projects.length === 6 ? rootSixGrid : rootFourGrid}>
      {projects &&
        _.map(projects, (project, index) => <ProjectPreview key={index} {...project} categorySlug={categorySlug} />)}
    </div>
  );
};

ProjectPreviewGrid.propTypes = {
  categorySlug: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProjectPreviewGrid;
