import { graphql } from 'gatsby';
import _ from 'lodash';
import React from 'react';

import ContentPage from '../../components/content-page';
import filterDocsWithoutSlugs from '../../utils/filter-docs-without-slugs';
import GraphQLErrorList from '../../components/graphql-error-list';
import Layout from '../../containers/layout';
import mapEdgesToNodes from '../../utils/map-edges-to-nodes';
import PageTitle from '../../components/page-title';
import ProjectPreviewGrid from '../../components/project-preview-grid';
import SEO from '../../components/seo';

export const query = graphql`
  query ProjectListTemplateQuery($catSlug: String!) {
    route: sanityRoute(id: { eq: "-9dc2f137-424a-5f58-bf4a-d0d28f208e10" }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    category: sanityCategory(slug: { current: { eq: $catSlug } }) {
      id
      title
      backgroundColour
    }
    projects: allSanityProject(
      sort: { fields: [publishedAt], order: DESC }
      filter: { categories: { elemMatch: { slug: { current: { eq: $catSlug } } } } }
    ) {
      edges {
        node {
          id
          heroImage {
            asset {
              _id
            }
            alt
          }
          title
          slug {
            current
          }
          backgroundColour
        }
      }
    }
  }
`;

const ProjectList = ({ children, data, errors, location, pageContext }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const page = data.page || data.route.page;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const projectNodes = data && data.projects && _.filter(mapEdgesToNodes(data.projects), filterDocsWithoutSlugs);
  const { category } = data;
  const menuItems = page.navMenu && (page.navMenu.items || []);
 
  return (
    <Layout menuItems={menuItems} backgroundColour={category.backgroundColour} isHomepage={false}>
      <SEO
        title={category.title}
        description={site.description}
        keywords={site.keywords}
        backgroundColour={category.backgroundColour}
      />
      <ContentPage>
        <PageTitle title={category.title} />
        <ProjectPreviewGrid projects={projectNodes} categorySlug={pageContext.catSlug} />
      </ContentPage>
    </Layout>
  );
};

export default ProjectList;
